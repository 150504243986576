import IconArrowDown from '/images/icons/arrow-down.raw.svg'
import IconArrowLeft from '/images/icons/arrow-left.raw.svg'
import IconArrowRight from '/images/icons/arrow-right.raw.svg'
import IconCheckmark from '/images/icons/checkmark.raw.svg'
import IconCheckmarkGreen from '/images/icons/checkmark-green.raw.svg'
import IconCheckmarkLightblue from '/images/icons/checkmark-lightblue.raw.svg'
import IconCheckmarkWhite from '/images/icons/checkmark-white.raw.svg'
import IconCheckmarkLarge from '/images/icons/checkmark-large.raw.svg'
import IconCross from '/images/icons/cross.raw.svg'
import IconQuestionMark from '/images/icons/question.raw.svg'
import IconStar from '/images/icons/star.raw.svg'
import IconHome from '/images/icons/home.raw.svg'

import IconBoekje from '/images/illustraties/boekje.raw.svg'
import IconHulpNodig from '/images/illustraties/hulp-nodig.raw.svg'
import IconPad from '/images/illustraties/pad.raw.svg'
import IconPrivacy from '/images/illustraties/privacy.raw.svg'
import IconWaarom from '/images/illustraties/waarom.raw.svg'

export {
  IconArrowDown,
  IconArrowLeft,
  IconArrowRight,
  IconCheckmark,
  IconCheckmarkGreen,
  IconCheckmarkLightblue,
  IconCheckmarkWhite,
  IconCheckmarkLarge,
  IconCross,
  IconQuestionMark,
  IconStar,
  IconHome,
  IconBoekje,
  IconHulpNodig,
  IconPad,
  IconPrivacy,
  IconWaarom,
}
